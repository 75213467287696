import React from "react";
import './App.css';
import Frame from "./Frame";
import { Outlet } from 'react-router-dom';


function App() {
 
  return (
    <div className="App">
      <Frame />
      <Outlet />
    </div>
  );
}

export default App;