import { createBrowserRouter } from "react-router-dom";
import App from './App';
import "./App.css";

import ListElements from "./ListElements";
import Stats from "./Stats";
import Orders from "./Orders";
import { ViewProduct } from "./ListElements";
import EditProduct from "./EditProduct"
import AddProduct from "./AddProduct";
import Login from "./Login";


const router = createBrowserRouter([
    {
        path: "/",
        element: <App />,
        children: [
            {
                index: true,
                element: <h1>Greetings</h1>
            }, {
                path: "/products/:query",
                element: <ListElements />
            }, {
                path: "/products",
                element: <ListElements />
            }, {
                path: "/products/id/:product_id/*",
                element: <ViewProduct />
            }, {
                path: "/orders",
                element: <Orders />
            }, {
                path: "/edit/products/:product_id",
                element: <EditProduct />
            }, {
                path: "/stats",
                element: <Stats />
            }, {
                path: "/add_product",
                element: <AddProduct />
            }, {
                path: "/login",
                element: <Login />
            }
        ]
    }, {
        path: "/*",
        element: <h2>Page not found, 404!</h2>
    }


]);



export default router;

