import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { baseURL } from './config';

function Login() {
    const [username, setUsername] = useState("");
    const [password, setPassword] = useState("");
    let navigate = useNavigate();

    const handleSubmit = (event) => {
        event.preventDefault();
        // Here you can add your logic to handle the login request



        // Create the request body with username and password
        const requestBody = {
            username: username,
            password: password
        };

        // Create the fetch request
        return fetch(`${baseURL}/login`, {        //adminapi/loginUrl
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(requestBody)
        })
            .then(response => {
                // Check if the response status is ok
                if (!response.ok) {
                    throw new Error('Login failed');
                }
                // Return the response JSON
                return response.json();
            })
            .then(data => {
                // Handle the response data
                console.log('Login successful:', data.message);
                localStorage.setItem('token', data.token);
                // Here you can perform additional actions such as redirecting the user
                // or storing authentication tokens

                // Reset the form after submission
                setUsername("");
                setPassword("");
                navigate("/")
            })
            .catch(error => {
                // Handle errors
                console.error('Error logging in:', error);
            });
    };



    return (
        <div>
            <h2>Login</h2>
            <form onSubmit={handleSubmit}>
                <div>
                    <label htmlFor="username">Username:</label>
                    <input
                        type="text"
                        id="username"
                        value={username}
                        onChange={(e) => setUsername(e.target.value)}
                        required
                    />
                </div>
                <div>
                    <label htmlFor="password">Password:</label>
                    <input
                        type="password"
                        id="password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                    />
                </div>
                <button type="submit">Login</button>
            </form>
        </div>
    );
}

export default Login;
