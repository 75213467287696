import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { useNavigate } from "react-router-dom";
import React, { useState } from "react";


function Frame() {
    const login = localStorage.getItem("token");
    let navigate = useNavigate();
    const [text, setText] = useState();
    React.useEffect(() => {
        if (login === null) {
            setText("Login")
        } else {
            setText("Log Out")
        }
    },[login]);



    function handleSubmit(event) {
        event.preventDefault(); // prevent the form from submitting
        if (login === null) {
            navigate("/login")
        } else {
            localStorage.removeItem('token');
            navigate("/")
        }
    }

    return (
        <div>
            <Navbar expand="lg" className="bg-body-tertiary mb-4">
                <Container>
                    <Navbar.Brand href="/">Traze</Navbar.Brand>
                    <Navbar.Toggle aria-controls="basic-navbar-nav" />
                    <Navbar.Collapse id="basic-navbar-nav">
                        <Nav className="me-auto">
                            <Nav.Link href="/stats">Stats</Nav.Link>
                            <Nav.Link href="/products">Products</Nav.Link>
                            <Nav.Link href="/orders">Orders</Nav.Link>
                            <div className="mx-auto">
                                <form onSubmit={handleSubmit}>
                                    <button type="submit" className="btn btn-link nav-link">{text}</button>
                                </form>
                            </div>
                        </Nav>
                    </Navbar.Collapse>
                </Container>
            </Navbar>
        </div>
    );
}

export default Frame;
