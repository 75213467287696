import React, { useState } from 'react';
import { baseURL } from './config';
import { useParams} from "react-router-dom";

const ZipFileUpload = () => {
    const [selectedFile, setSelectedFile] = useState(null);
    const [successMessage, setSuccessMessage] = useState('');
    const [errorMessage, setErrorMessage] = useState('');
    let params = useParams();

    const handleFileChange = (e) => {
        setSelectedFile(e.target.files[0]);
    };

    const handleUpload = () => {
        if (!selectedFile) {
            setErrorMessage('Please select a file to upload.');
            return;
        }

        const formData = new FormData();
        formData.append('file', selectedFile); // Change 'image' to 'zipFile' to match the server-side handling

        fetch(`${baseURL}/upload/media/`+ params.product_id, {
            method: 'POST',
            headers: {
                'Authorization': localStorage.getItem("token")
            },
            body: formData
        })
        .then(response => {
            console.log(params.product_id)
            if (!response.ok) {
                throw new Error('Network response was not ok');
            }
            return response.json();
        })
        .then(data => {
            console.log(data);
            setSuccessMessage(data.message); // Assuming server responds with a message
            setErrorMessage(''); // Clear any previous error message
        })
        .catch(error => {
            console.error('Error uploading zip file:', error);
            setErrorMessage('Error uploading zip file. Please try again.'); // Set error message
        });
    };

    return (
        <div>
            <input type="file" accept=".zip" onChange={handleFileChange} />
            <button onClick={handleUpload}>Upload ZIP File</button>
            {successMessage && <p>{successMessage}</p>} {/* Display success message if available */}
            {errorMessage && <p>{errorMessage}</p>} {/* Display error message if available */}
        </div>
    );
};

export default ZipFileUpload;
