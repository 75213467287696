import React from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Outlet } from "react-router-dom";
import { baseURL } from './config';
import ZipFileUpload from "./ZipFileUpload";
import { Carousel } from 'react-bootstrap';

function ListElements() {

    const [data, setData] = React.useState(null);
    let navigate = useNavigate();
    let params = useParams();

    React.useEffect(() => {
        fetch(`${baseURL}/products?` + params.query, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
            .then((res) => {
                return res.json()
            })
            .then((data) => {
                setData(data.data)
            }).catch((error) => {
                console.error("Error fetching products:", error);
            });
    }, [params.query]);

    function handleSubmit(event) {
        event.preventDefault(); // prevent the form from submitting
        const formData = new FormData(event.target);
        const name = formData.get("name");
        const query = "name_like=" + name;
        fetch(`${baseURL}/products?` + query, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        }) /// När ladda upp på inleed, skriv /adminapi/products/
            .then((res) => {
                console.log(res)
                return res.json()
            })
            .then((data) => {
                setData(data.data)
                console.log(data.data)
            });
        navigate("/products/" + query)
    }

    function toAddProduct(event) {
        event.preventDefault(); // prevent the form from submitting
        navigate("/add_product")
    }

    function Table({ data }) {
        return (
            <table className="table">
                <thead>
                    <tr>
                        <th colSpan="1" className="text-center">Name</th>
                        <th colSpan="1" className="text-center">Description</th>
                        <th colSpan="1" className="text-center">Sell price</th>
                    </tr>
                </thead>
                <tbody>
                    {!data ? (
                        <tr key="loading">
                            <td colSpan="3" className="text-center">Make a search!</td>
                        </tr>
                    ) : (
                        data.map((element) => (
                            <tr key={element.productId}>
                                <td><img src={"https://trazedev.se/" + element.thumbnail} alt="logo" height="60"></img></td>
                                <td><a href={`/products/id/${element.productId}`}>{element.name}</a></td>
                                <td>{element.description}</td>
                                <td>{element.sell_price}</td>
                                <td><a href={`/edit/products/${element.productId}`}>Edit</a></td>
                            </tr>
                        ))
                    )}
                </tbody>
            </table>
        );
    }

    return (
        <div className="container">
            <div className="container">
                <div className="mb-3 row g-3">
                    <form onSubmit={handleSubmit} noValidate className="col-auto">
                        <div className="input-group">
                            <input type="text" id="name" name="name" className="form-control" placeholder="Product Name" style={{ maxWidth: "200px" }} />
                            <button type="submit" name="submitType" value="search" className="btn btn-primary">Submit</button>
                        </div>
                    </form>
                    <div className="col-auto ms-auto">
                        <form onSubmit={toAddProduct} noValidate className="col-auto">
                            <button type="submit" name="submitType" value="addProduct" className="btn btn-primary">Add Product</button>
                        </form>
                    </div>
                </div>
            </div>
            <Table data={data} />
            <Outlet />
        </div>


    )
}

function ViewProduct() {
    let params = useParams();
    const [product, setProduct] = React.useState(null);
    let navigate = useNavigate();

    React.useEffect(() => {
        fetch(`${baseURL}/products/` + params.product_id, {
            headers: {
                'Authorization': localStorage.getItem("token")
            }
        })
            .then((res) => {
                console.log(res)
                return res.json()
            })

            .then((data) => {
                console.log(data);
                setProduct(data.data);
            })
            .catch((error) => {
                console.error("Error fetching product:", error);
            });
    }, [params.product_id]);

    const handleDeleteImage = (index) => {
        let imageIdToDelete = product.media.media_ids[index]; // Get the ID of the image to delete
        let jsonData = { "media_rm_list": [imageIdToDelete] }; // Pass the ID of the image to delete
        jsonData = JSON.stringify(jsonData)
        console.log(index)
        fetch(`${baseURL}/remove/media`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                // Add any necessary authentication headers
                'Authorization': localStorage.getItem("token")
            },
            body: jsonData
        })
            .then(response => {
                if (!response.ok) {
                    // If response is not ok (status code is not in the range 200-299)
                    // You can access the status code and the text message returned by the server
                    console.log(`Server returned status ${response.status}: ${response.statusText}`);
                    // You may also want to parse the response body if it's JSON
                    return response.json().then(data => {
                        throw new Error(data.message); // Throw an error with the message from the server
                    });
                }
                // If response is ok, continue with parsing JSON
                navigate("products/id/"+product.product_id+"/removed_image")
            })
            .catch(error => {
                console.error('Error deleting image:', error);
            });

    };

    return (
        <div>
            {!product ? (
                <h1>Loading...</h1>
            ) : (
                <div>
                    <h2>{product.name}</h2>
                    <p>Description: {product.description}</p>
                    <p>Sell Price: {product.sell_price}</p>
                    <p>Specifications: {product.specification}</p>

                    <div className="container">
                        <div className="row justify-content-center align-items-start">
                            <div className="col-md-6 flex-column justify-content-center align-items-center" style={{ backgroundColor: '#f0f0f0' }}>
                                <h3>Images:</h3>
                                <Carousel fade={false} interval={null}>
                                    {product.media.images.map((image, index) => (
                                        <Carousel.Item key={index}>
                                            <div style={{ position: 'relative', display: 'inline-block' }}>
                                                <img
                                                    className="d-block w-100"
                                                    src={"https://trazedev.se/" + image}
                                                    alt={"Product image " + (index + 1)}
                                                    style={{ maxHeight: "300px", objectFit: "contain" }}
                                                />
                                                <button
                                                    className="btn btn-danger delete-button"
                                                    style={{ position: 'absolute', top: '10px', right: '10px' }}
                                                    onClick={() => handleDeleteImage(index)} // Assuming handleDeleteImage is your delete handler function
                                                >
                                                    Delete
                                                </button>
                                            </div>
                                        </Carousel.Item>
                                    ))}
                                </Carousel>
                            </div>
                            <div className="col-md-6 d-flex flex-column justify-content-center align-items-center" style={{ backgroundColor: '#f0f0f0' }}>
                                <h3>Thumbnail</h3>
                                <img
                                    className="d-block"
                                    src={"https://trazedev.se/" + product.media.thumbnail}
                                    alt={"Product thumbnail"}
                                    style={{ height: "300px" }} // Set max height and auto width
                                />
                            </div>
                        </div>
                    </div>
                    <ZipFileUpload />
                </div>
            )}
        </div>
    );
}

export default ListElements;

export { ViewProduct };